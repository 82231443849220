import { Box, Center, Flex, Input, VStack } from "@chakra-ui/react";
import React, { useState } from "react";
import { COLORS_PRIMARY } from "../shared/opalite_colors";
import {
  OpaliteButtonInput,
  OpaliteButtonStandard,
} from "../shared/opalite_buttons";
import {
  OpaliteTextHighlighted,
  OpaliteTextNFTLink,
  OpaliteTextStandard,
  OpaliteTextTitle,
} from "../shared/opalite_text";
import { navigate } from "gatsby";
import BackgroundAmorphusGradient from "../../images/background_amorphus_gradient.svg";
import BackgroundAmorphusGradientDesktop from "../../images/background_amorphus_gradient_desktop_from_bottom.svg";

interface PhantomConnectionProps {}

const PhantomConnection: React.FC<PhantomConnectionProps> = (props) => {
  const [connectionNumber, setConnectionNumber] = useState();
  const [connectionNumberDisplay, setConnectionNumberDisplay] = useState("");
  const formatText = (e) => {
    if (e.target.value.length <= 16) {
      setConnectionNumber(e.target.value);
      setConnectionNumberDisplay(
        e.target.value.replace(/(\d{4})(\d{4})(\d{4})(\d{4})/, "$1-$2-$3-$4")
      );
    }
  };
  return (
    <Box
      padding="2rem"
      backgroundColor={COLORS_PRIMARY.BACKGROUND}
      height="100vh"
      display="flex"
      flexDirection="row"
      backgroundRepeat="no-repeat"
      backgroundImage={{
        base: BackgroundAmorphusGradient,
        sm: BackgroundAmorphusGradientDesktop,
      }}
      style={{
        backgroundRepeat: "no-repeat",
        backgroundSize: "100%",
        backgroundPosition: "center 50px",
        backgroundColor: COLORS_PRIMARY.BACKGROUND,
      }}
    >
      <VStack width="100%">
        <OpaliteTextTitle
          padding="2rem"
          width={{ base: "100%", md: "30%", xl: "25%" }}
          position="fixed"
          bottom="4rem"
          left="0"
          flexDirection="row"
        >
          Drop your hash number below.
        </OpaliteTextTitle>
        <Center
          padding="2rem"
          width={{ base: "100%", md: "30%", xl: "20%" }}
          position="fixed"
          bottom="0"
          left="0"
          flexDirection="row"
        >
          <Input
            backgroundColor="#1b1b1b"
            width="75%"
            color={COLORS_PRIMARY.FONT}
            borderRadius="5px 0 0 5px"
            placeholder="0000-0000-0000-0000"
            borderColor={COLORS_PRIMARY.FONT}
            borderWidth="2px"
            borderRightWidth="0"
            onChange={(e) => formatText(e)}
            value={connectionNumberDisplay}
          ></Input>{" "}
          <OpaliteButtonInput onClick={() => navigate("/success")}>
            Next
          </OpaliteButtonInput>
        </Center>
      </VStack>
    </Box>
  );
};

export default PhantomConnection;
