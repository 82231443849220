import { ChakraProvider } from "@chakra-ui/react";
import React from "react";
import Header from "../components/header/header";
import PhantomConnection from "../components/phantom_connection/phantom_connection";

function PhantomConnectionPage() {
  return (
    <ChakraProvider>
      <Header />
      <PhantomConnection />
    </ChakraProvider>
  );
}

export default PhantomConnectionPage;
